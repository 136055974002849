<template>
  <div>
    <van-nav-bar title="提现" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:46px;overflow: hidden;background: #f5f5f5" @click.stop>
      <div class="weixin">到账微信零钱</div>
      <div class="hest">
        <div style="margin-top: 15%;font-size: 14px;padding-top: 30px">提现金额</div>
        <div style="margin-top: 10px;font-weight: bold;">
          <span style="font-size: 34px;">￥</span>
          <!-- <div></div> -->
          <input autofocus="autofocus" v-model="money" readonly class="ips" type="text" />
        </div>
        <div class="yse">
          当前可提现余额{{balance}}元，
          <span style="color: #224b8f" @click="toquanbu">全部提现</span>
        </div>
      </div>
    </div>
    <div class="van-number-keyboard">
      <div class="van-number-keyboard__body">
        <div class="van-number-keyboard__keys">
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("1")' data-num="1">
            <div class="van-key">1</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("2")' data-num="2">
            <div class="van-key">2</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("3")' data-num="3">
            <div class="van-key">3</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("4")' data-num="4">
            <div class="van-key">4</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("5")' data-num="5">
            <div class="van-key">5</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("6")' data-num="6">
            <div class="van-key">6</div>
          </div>
          <!-- <div class='key-cell' data-num='C'>清空</div> -->
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("7")' data-num="7">
            <div class="van-key">7</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("8")' data-num="8">
            <div class="van-key">8</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("9")' data-num="9">
            <div class="van-key">9</div>
          </div>
          <!-- <div class='key-cell' data-num='-1'></div>
                    <div class='key-cell disabled' data-num='-1'></div>
          <div class='key-cell' data-num='.'>.</div>-->
          <div
            class="van-key__wrapper van-key__wrapper--wider"
            @click.stop='_handleKeyPress("0")'
            data-num="0"
          >
            <div class="van-key">0</div>
          </div>
          <div class="van-key__wrapper" @click.stop='_handleKeyPress(".")' data-num=".">
            <div class="van-key">.</div>
          </div>
        </div>
        <div class="van-number-keyboard__sidebar">
          <div class="van-key__wrapper" @click.stop='_handleKeyPress("D")' data-num="D">
            <div class="van-key van-key--large van-key--delete"><img class="scimg" src="../../assets/img/scshuzi.png" alt=""></div>
          </div>

          <div class="van-key__wrapper" @click.stop='_handleKeyPress("S")' data-num="S">
            <div class="van-key van-key--blue van-key--large">提现</div>
          </div>
        </div>
        <!-- <div class='key-confirm' data-num='S'>确认</div> -->
      </div>
    </div>
    <van-popup v-model="showisok" class="titcan" title="提现">
      <div class="his">提现</div>
      <div class="jines">
        <span style="font-size: 16px">￥</span>
        <span>{{moneys}}</span>
        
      </div>
      <div class="bottsc">
        <van-button class="left" type="danger" size="small" @click="showisok= false">取 消</van-button>
        <van-button class="right" type="primary" size="small" v-preventReClick @click="totijiao">确 认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {sellManinCash} from "../../api/check";
import preventReClick from "../../assets/preventReClick";
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: {}, //
      show: true,
      showisok: false,
      amount: "",
      moneys: 0,
      money: "",
      balance: this.$route.query.balance,
      active: 1,
      count: "",
      token: "",
      type: 1,
      isok: false
    };
  },
  methods: {
    //全部提现
    toquanbu() {
      
      this.money = String(this.balance)
    },
    //确认提交
    totijiao() {
        var _this = this
      let S = this.money;
      S = Number(S).toFixed(2);
      sellManinCash({cashAmount:S}).then(res => {
        console.log(res);
        if (res.success) {
          this.showisok = false;
          this.$toast.success("提现成功!");
          setTimeout(() => {
           _this.$router.go(-1);
          }, 1000);
        } else {
          this.$toast.fail(res.data.message);
        }
      });
    },
    //处理按键
    _handleKeyPress(e) {
      // let num = e.target.dataset.num;
      let num = e;
      console.log(e);
      //不同按键处理逻辑
      // -1 代表无效按键，直接返回
      if (num == -1) return false;
      switch (String(num)) {
        //小数点
        case ".":
          this._handleDecimalPoint();
          break;
        //删除键
        case "D":
          this._handleDeleteKey();
          break;
        //清空键
        case "C":
          this._handleClearKey();
          break;
        //确认键
        case "S":
          this._handleConfirmKey();
          break;
        default:
          this._handleNumberKey(num);
          break;
      }
    },
    //处理小数点函数
    _handleDecimalPoint() {
      //如果包含小数点，直接返回
      if (this.money.indexOf(".") > -1) return false;
      //如果小数点是第一位，补0
      if (!this.money.length) this.money = "0.";
      //如果不是，添加一个小数点
      else this.money = this.money + ".";
    },
    //处理数字
    _handleNumberKey(num) {
      let S = this.money;
      //如果有小数点且小数点位数不小于2
      if (S.indexOf(".") > -1 && S.substring(S.indexOf(".") + 1).length < 2)
        this.money = S + num;
      //没有小数点
      if (!(S.indexOf(".") > -1)) {
        //如果第一位是0，只能输入小数点
        if (num == 0 && S.length == 0) this.money = "0.";
        else {
          if (S.length && Number(S.charAt(0)) === 0) return;
          this.money = S + num;
        }
      }
    },
    //处理清空键
    _handleClearKey() {
      this.money = "";
    },
    _handleConfirmKey() {
      var vm = this;
      let S = this.money;
      console.log(S)
      //未输入
      if (S < 0) {
        this.$toast("您目前未输入!");
        return false;
      }
      //将 8. 这种转换成 8.00
      if (S.indexOf(".") > -1 && S.indexOf(".") == S.length - 1)
        S = Number(S.substring(0, S.length - 1)).toFixed(2);
      //保留两位
      S = Number(S).toFixed(2);
      if (0 >= S) {
        this.$toast("输入金额不能为0！");
        return false;
      }
      // if (this.balance <= S) {
      //     this.$toast("输入金额超过零钱余额！");
      //     return false;
      // }
      this.moneys = S;
      this.showisok = true;

      // this.$emit('confirmEvent', S)
    },
    //处理删除键
    _handleDeleteKey() {
      let S = this.money;
      //如果没有输入，直接返回
      if (!S.length) return false;
      //否则删除最后一个
      this.money = S.substring(0, S.length - 1);
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    // this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.ips {
  line-height: 45px;
  font-size: 45px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #dcdcdc;
}
.scimg{
    width: 25px;
}
.yse {
  color: #666;
  margin-top: 15px;
  margin-left: 5px;
  font-size: 14px;
}

.van-key--blue {
  background: #07c160;
}

.hest {
  background: #fff;
  border-radius: 20px;
  padding: 0 15px;
}

.weixin {
  margin-top: 20px;
  margin-left: 15px;
  font-size: 15px;
}

.titcan {
  width: 240px;
  padding: 5px 0 20px 0;
  border-radius: 10px;
  /* height: 200px; */
}

.his {
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.jines {
  font-size: 24px;
  text-align: center;
  padding: 0px 0 20px 0;
}

.bottsc {
  width: 140px;
  margin: auto;
  overflow: hidden;
}
.bottsc .van-button {
  padding: 0 15px;
}
</style>